const picsData = [
    {
        id: 1,
        url: "https://diem25bingo.realvinylz.net/diem25memes/brice_teasing_web.jpg"
    },
    {
        id: 2,
        url: "https://diem25bingo.realvinylz.net/diem25memes/castro_listening_web.jpg"
    },
    {
        id: 3,
        url: "https://diem25bingo.realvinylz.net/diem25memes/jakob_goodvibes_web.jpg"
    },
    {
        id: 4,
        url: "https://diem25bingo.realvinylz.net/diem25memes/jakob_investigating_web.jpg"
    },
    {
        id: 5,
        url: "https://diem25bingo.realvinylz.net/diem25memes/judith_explaining_things_web.jpg"
    },
    {
        id: 6,
        url: "https://diem25bingo.realvinylz.net/diem25memes/maarten_web.jpg"
    },
    {
        id: 6,
        url: "https://diem25bingo.realvinylz.net/diem25memes/maarten_web.jpg"
    },
    {
        id: 7,
        url: "https://diem25bingo.realvinylz.net/diem25memes/erik_mic_web.jpg"
    },
    {
        id: 8,
        url: "https://diem25bingo.realvinylz.net/diem25memes/yanis_explaining_leadership_web.jpg"
    },

    {
        id: 9,
        url: "https://diem25bingo.realvinylz.net/diem25memes/yanis_talking_2_web.jpg"
    },
    {
        id: 10,
        url: "https://diem25bingo.realvinylz.net/diem25memes/mehran_rosanna_web.jpg"
    },
    {
        id: 11,
        url: "https://diem25bingo.realvinylz.net/diem25memes/erik_cringe_web.jpg"
    },
    {
        id: 12,
        url: "https://diem25bingo.realvinylz.net/diem25memes/claudia_argueing_web.jpg"
    },
    {
        id: 13,
        url: "https://diem25bingo.realvinylz.net/diem25memes/judith_thinking_web.jpg"
    },
    {
        id: 14,
        url: "https://diem25bingo.realvinylz.net/diem25memes/srecko_smiling_web.jpg"
    },
    {
        id: 15,
        url: "https://diem25bingo.realvinylz.net/diem25memes/voting_procedure_web.jpg"
    },
    {
        id: 16,
        url: "https://diem25bingo.realvinylz.net/diem25memes/david_adler_janina_web.jpg"
    },
    {
        id: 17,
        url: "https://diem25bingo.realvinylz.net/diem25memes/david_adler_laughing_web.jpg"
    },
    {
        id: 18,
        url: "https://diem25bingo.realvinylz.net/diem25memes/claudia_simona_web.jpg"
    },
    {
        id: 19,
        url: "https://diem25bingo.realvinylz.net/diem25memes/erik_thinking_web.jpg"
    },
    {
        id: 20,
        url: "https://diem25bingo.realvinylz.net/diem25memes/YV_press_conference_1_web.jpg"
    },
    {
        id: 21,
        url: "https://diem25bingo.realvinylz.net/diem25memes/YV_press_conference_2_web.jpg"
    },
    {
        id: 22,
        url: "https://diem25bingo.realvinylz.net/diem25memes/YV_press_conference_3_web.jpg"
    },
    {
        id: 23,
        url: "https://diem25bingo.realvinylz.net/diem25memes/YV_press_conference_4_web.jpg"
    },



]

export default picsData