import React , {Component} from "react"
import picsData from "./diem25pics"

class MemeGenerator extends Component {

    constructor() {
        super()
        this.state = {
            topText: "",
            bottomText: "",
            randomImg: "https://diem25bingo.realvinylz.net/diem25memes/yanis_talking_2_web.jpg",
            allMemeImgs: []
        }

        
    }
    /*
    componentDidMount() {
        fetch("https://api.imgflip.com/get_memes")
            .then(response => response.json())
            .then(response => {
                const {memes} = response.data
                 this.setState({
                    allMemeImgs: memes
                }) 
            })
    }

    */

   componentDidMount() {
    
             this.setState({
                allMemeImgs: picsData
            }) 
        
}


    changeHandler = (event) => {
        const {name, value} = event.target

        this.setState({[name]: value})
    }

    randomHandler = (event) => {
        event.preventDefault()
        let randomValue = Math.floor(Math.random() * this.state.allMemeImgs.length);
        console.log(this.state.allMemeImgs[randomValue].url)
        
        this.setState(
            { randomImg: this.state.allMemeImgs[randomValue].url}
        ) 
        

    }
    


    render() {

        return (
            <div>
                <form className="meme-form" onSubmit={this.randomHandler}>
                   <input
                    type="text"
                    name="topText"
                    value={this.state.topText}
                    placeholder="Type your upper text"
                    onChange={this.changeHandler}
                    />
                    <p />
                    <input
                    type="text"
                    name="bottomText"
                    value={this.state.bottomText}
                    placeholder="Type your lower text"
                    onChange={this.changeHandler}
                    />
                    <p />
                
                    <button>Gen</button>
                </form>
                <div className="meme">
                    <img src={this.state.randomImg} alt="" />
                    <h2 className="top">{this.state.topText}</h2>
                    <h2 className="bottom">{this.state.bottomText}</h2>
                </div>
            </div>
        )


    }


}

export default MemeGenerator