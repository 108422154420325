import React from "react"

function Header() {
 return (
    <header>
    <img 
        src="https://diem25bingo.realvinylz.net/diem25memes/Yanis.png" 
        alt="Problem?"
    />
    <p>DiEM25 Meme Generator</p>
</header>
 )
 }



export default Header