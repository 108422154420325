
import React , {Component} from "react"
import Header from "./Header"
import MemeGenerator from "./MemeGenerator"


class App extends Component {
    


  render() {
    
    
    return (
        <div>
          <Header />
          <p />
            
            <MemeGenerator />
        </div>
    )

  }
}




export default App

